// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import { USER_CONNECTED, USER_AUTH, USER_LOGOUT, USER_GETSELF, IAction } from '../actions/types';

export interface IUserState {
    connected: boolean,
	login: string
}

const initialState: IUserState = {
    connected: false,
	login:""
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(USER_AUTH, (state, action: IAction) => {
		state.connected = true;
	});
	builder.addCase(USER_LOGOUT, (state, action: IAction) => {
		localStorage.removeItem('userAdminToken');
		state.connected = false;
		state.login = ""
	});
	builder.addCase(USER_CONNECTED, (state, action: IAction) => {
		state.connected = action.payload;
	});
	builder.addCase(USER_GETSELF, (state, action: IAction) => {
		state.login = action.payload.user.login
	});
});
