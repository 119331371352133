// React
import { configureStore } from '@reduxjs/toolkit';
// App Files
import { reducer as reducer_user, IUserState } from './reducer_user';
import { reducer as reducer_scene, ISceneState } from './reducer_scene';
import {reducer as reducer_popup, IPopupState} from './reducer_popup';

export interface IRootState {
	user: IUserState;
    scene: ISceneState;
	popup: IPopupState;
}

const store = configureStore({
	reducer: {
		user: reducer_user,
        scene: reducer_scene,
		popup: reducer_popup
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
