// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import {
    SCENE_RESET,
    SCENE_ALL,
    IAction
} from '../actions/types';

export interface ISceneState {
    scenes: any
}

const initialState: ISceneState = {
    scenes:[]
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(SCENE_ALL, (state, action: IAction) => {
        state.scenes = action.payload.nodes;
	});
    builder.addCase(SCENE_RESET, (state, action: IAction) => {
        state = {
            ...initialState
        }
	});
});
