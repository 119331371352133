// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import { POPUP_DISPLAY, IAction } from '../actions/types';

export interface IPopupState {
    display: boolean,
}

const initialState: IPopupState = {
    display: true,
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase( POPUP_DISPLAY,(state, action: IAction) => {
		state.display = action.payload;
	});
});
